import { ITEM_SIZE, MEMORY_SIZES } from '@mtntop/utils/src/utils/productSizes';
export const SIZES = [...ITEM_SIZE, ...ITEM_SIZE.map((e) => `${e} T`), ...MEMORY_SIZES];
export const findQuerySize = (query, part) => {
  if (part.ApparelSize && part.ApparelSize.label_size && query.sizes) {
    try {
      if (!Array.isArray(query.sizes)) query.sizes = [query.sizes];
      return query.sizes
        .map((e) => {
          const s = e.split('-');
          const value = s.pop();
          return { [s.join('-').toLowerCase()]: parseInt(value) };
        })
        .find((e) => e[part.ApparelSize.label_size.toLowerCase()]);
    } catch (e) {}
  }
};
export const checkQuerySize = (query, part) => {
  let quantity = null;
  const size = findQuerySize(query, part);
  if (size) {
    quantity = size[part.ApparelSize.label_size.toLowerCase()] || 1;
  }
  return quantity;
};
