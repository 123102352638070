
  export default {
    name: 'skeleton',
    props: {
      loaded: { type: Boolean },
      noProgress: { type: Boolean },
      size: { type: String },
    },
    data() {
      return {
        loading: !this.noProgress,
        delay: null,
      };
    },
    computed: {
      isVisibleSkeleton() {
        return !this.noProgress && !this.$route.query.download && (!this.loaded || this.loading);
      },
      wrapperSyles() {
        const styles = {
          position: 'relative',
          'min-height': this.size ? `${this.size}px` : 'auto',
          'margin-bottom': this.size ? `10px` : 'auto',
        };
        return this.isVisibleSkeleton ? styles : {};
      },
    },
    mounted() {
      if (!this.noProgress) {
        this.delay = setTimeout(() => {
          this.loading = false;
          this.$emit('loaded');
        }, 100);
      }
    },
    destroyed() {
      if (this.delay) clearTimeout(this.delay);
    },
  };
